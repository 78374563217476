import * as React from "react";
import Redirect from "../../../components/redirect";
//--------------------------------------------------

  const url = "https://docs.google.com/spreadsheets/d/1PKSHhtMR4bk8NHOecL5lGT-Sj-Jl4CpO-89YH-p4Y-w/edit?usp=sharing";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => (
  <Redirect goto_url={url} />
);

export default IndexPage;
